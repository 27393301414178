import * as React from "react";
import PageWrapper from "../components/PageWrapper";
import { ROUTE_LINKS } from "../utils/routes";
import ProfilePic from "../images/profile.png";
import HousePic from "../images/house.png";
import Paragraph from "../components/Paragraph";
import SEO from "../components/SEO";

const AboutUs: React.FC = () => {
  return (
    <PageWrapper path={ROUTE_LINKS.aboutUs}>
      <SEO pageUrl={ROUTE_LINKS.aboutUs} />
      <main className="max-w-6xl md:mx-auto p-2.5 pb-12">
        <section className="flex flex-col md:flex-row md:items-center lg:-mt-20 justify-between min-h-[800px] md:min-h-[500px] lg:min-h-[600px]">
          <div className="w-full md:w-5/12">
            <Paragraph title="The Facts">
              There are over 48 million rental homes in the United States 35% of
              Americans rent homes.
              <br />
              The average tenant screening takes 3-5 business days
            </Paragraph>
            <Paragraph title="Our Vision">
              Matrix is a tenant analysis fintech platform that is changing the
              landscape of the growing, $500 Billion rental housing market. Our
              proprietary scoring model called MAIA (Matrix Asset &amp; Income
              Analysis) produces a more thorough, inclusive tenant scoring that
              allows landlords and property managers to underwrite and approve
              tenants in less than 15 minutes, particularly in the area of
              affordable housing.
            </Paragraph>
          </div>
          <div className="w-full md:w-7/12 md:ml-4">
            <img src={HousePic} alt="House" title="House" />
          </div>
        </section>
        <section className="mt-3 min-h-[450px]">
          <h2 className="mb-1 text-4xl font-bold  heading">Our Team</h2>
          <img
            src={ProfilePic}
            alt="Profile"
            title="Profile Pic"
            className="w-96 lg:w-72 max-h-[400px]"
          />
          <h3 className="text-4xl font-bold capitalize">Sipho Simela</h3>
          <h6 className="text-2xl  heading">CEO/ Founder</h6>
        </section>
      </main>
    </PageWrapper>
  );
};

export default React.memo(AboutUs);
